<template>
  <div class="container">
    <sub-header :pathList="pathList" :title="title"></sub-header>
    <div id="contents" class="contents">
      <div id="detail">
        <div class="title-sort-box">
          <h3>{{ $t('rule.detail-title') }}</h3>
        </div>
        <!--/title-sort-box-->
        <article class="board-view">
          <div class="alarm-rule-wrapper">
            <table class="alarm-rule-table">
              <colgroup>
                <col style="width:15%" />
                <col style="width:32%" />
              </colgroup>
              <tbody>
                <tr>
                  <td colspan="2"></td>
                </tr>
                <tr>
                  <th>
                    {{ $t('rule.detail-item1') }}
                  </th>
                  <td>
                    {{ detail.alarmRuleId }}
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ $t('rule.detail-item2') }}
                  </th>
                  <td>
                    {{ detail.alarmRuleNm }}
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ $t('rule.detail-item3') }}
                  </th>
                  <td>
                    {{ detail.srvcId }}
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ $t('rule.detail-item4') }}
                  </th>
                  <td>
                    <table class="alarm-rule-dtl-table">
                      <colgroup>
                        <col style="width:16%" />
                        <col style="width:32%" />
                        <col style="width:20%" />
                        <col style="width:32%" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th></th>
                          <th>{{ $t('rule.detail-item4-head1') }}</th>
                          <th>{{ $t('rule.detail-item4-head2') }}</th>
                          <th>{{ $t('rule.detail-item4-head3') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(alarmRule, index) in detail.alarmRules"
                          :key="index"
                        >
                          <td>{{ $t('rule.detail-item4') + (index + 1) }}</td>
                          <td>{{ alarmRule.name }}</td>
                          <td>{{ alarmRule.scopeCode }}</td>
                          <td>{{ alarmRule.ruleValue }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ $t('rule.detail-item5') }}
                  </th>
                  <td>
                    {{ detail.genderCode }}
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ $t('rule.detail-item6') }}
                  </th>
                  <td>
                    {{ detail.alarmTyCode }}
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ $t('rule.detail-item7') }}
                  </th>
                  <td>
                    {{ detail.ruleTyCode }}
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ $t('rule.detail-item8') }}
                  </th>
                  <td>
                    {{ detail.alarmCycleValue }}
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ $t('rule.detail-item9') }}
                  </th>
                  <td>
                    {{ detail.alarmMsgCn }}
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ $t('rule.detail-item10') }}
                  </th>
                  <td>
                    {{ detail.crtrId }}
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ $t('rule.detail-item11') }}
                  </th>
                  <td>
                    {{ detail.creatDt }}
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ $t('rule.detail-item12') }}
                  </th>
                  <td>
                    {{ detail.updusrId }}
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ $t('rule.detail-item13') }}
                  </th>
                  <td>
                    {{ detail.updtDt }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </article>
        <div class="btns-bottom">
          <button
            type="button"
            class="bt-md bt-default"
            @click="goToRuleList()"
          >
            <span>목록</span>
          </button>
          <button
            type="button"
            class="bt-md bt-red"
            @click="doDeleteRule(detail.alarmRuleSn)"
          >
            <span>삭제</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchRuleDetail, deleteRule } from '@/api/subscriptionApi';

export default {
  data() {
    return {
      pathList: [
        this.$i18n.t('menu.home'),
        this.$i18n.t('menu.subscriptionAdmin'),
        this.$i18n.t('subMenu.rule'),
      ],
      title: {
        mainTitle: this.$i18n.t('menu.subscriptionAdmin'),
        smallTitle: this.$i18n.t('menu.subscriptionAdmin-eng'),
      },
      detail: {
        alarmRuleSn: '',
        alarmRuleId: '',
        alarmRuleNm: '',
        srvcId: '',
        alarmRules: [],
        genderCode: '',
        alarmTyCode: '',
        ruleTyCode: '',
        alarmCycleValue: '',
        alarmMsgCn: '',
        crtrId: '',
        creatDt: '',
        updusrId: '',
        updtDt: '',
      },
    };
  },
  methods: {
    async doGetRuleDetail(paramAlarmRuleSn) {
      try {
        const { data } = await fetchRuleDetail(paramAlarmRuleSn);
        const {
          alarmRuleSn,
          alarmRuleId,
          alarmRuleNm,
          srvcId,
          alarmTyCode,
          ruleTyCode,
          alarmCycleValue,
          alarmMsgCn,
          crtrId,
          creatDt,
          updusrId,
          updtDt,
          ruleDtlVOList,
        } = data;

        this.detail.alarmRuleSn = alarmRuleSn;
        this.detail.alarmRuleId = alarmRuleId;
        this.detail.alarmRuleNm = alarmRuleNm;
        this.detail.srvcId = srvcId;
        this.detail.alarmCycleValue = alarmCycleValue;
        this.detail.alarmMsgCn = alarmMsgCn;
        this.detail.crtrId = crtrId;
        this.detail.creatDt = creatDt;
        this.detail.updusrId = updusrId;
        this.detail.updtDt = updtDt;

        let genderCode;
        for (let i in ruleDtlVOList) {
          let ruleDtl = ruleDtlVOList[i];
          const { alarmItemCode, ruleValue } = ruleDtl;
          if (alarmItemCode === 'gender') {
            genderCode = ruleValue;
            break;
          }
        }

        this.detail.alarmTyCode =
          alarmTyCode === '&&'
            ? this.$i18n.t('rule.alarmTyCode1')
            : this.$i18n.t('rule.alarmTyCode2');
        this.detail.ruleTyCode =
          ruleTyCode === 'ALARM'
            ? this.$i18n.t('rule.ruleTyCode1')
            : this.$i18n.t('rule.ruleTyCode2');
        this.detail.alarmRules = this.getAlarmRules(ruleDtlVOList);
        this.detail.genderCode =
          genderCode === 'M'
            ? this.$i18n.t('rule.gender1')
            : this.$i18n.t('rule.gender2');
      } catch (error) {
        console.log('error', error);
      }
    },
    getAlarmRules(ruleDtlVOList) {
      let alarmRules = [];
      for (let i in ruleDtlVOList) {
        const ruleDtl = ruleDtlVOList[i];
        const { alarmItemCode, scopeCode, ruleValue } = ruleDtl;
        if (alarmItemCode === 'gender') {
          continue;
        }
        this.alarmRuleItems.forEach(alarmRuleItem => {
          const { codeName, description } = alarmRuleItem;
          if (alarmItemCode === codeName) {
            alarmRules.push({ name: description, scopeCode, ruleValue });
          }
        });
      }
      return alarmRules;
    },
    goToRuleList() {
      const page = this.$route.params.page;
      this.$router.push({ name: 'rule', params: { page } });
    },
    async doDeleteRule(alarmRuleSn) {
      const deleteMsg = this.$i18n.t('rule.delete-message');
      const isConfirm = await this.$dialogs.confirm(deleteMsg);
      if (isConfirm) {
        try {
          const { data } = await deleteRule(alarmRuleSn);
          const { valid } = data;
          if (valid) {
            const deleteCompleteMsg = this.$i18n.t(
              'rule.delete-complete-message',
            );
            const isOk = await this.$dialogs.alert(deleteCompleteMsg);
            if (isOk) {
              this.$router.go(-1);
            }
          }
        } catch (error) {
          console.log('error', error);
        }
      }
    },
  },
  computed: {
    alarmRuleItems() {
      return this.$store.state.subscription.alarmRuleItems;
    },
  },
  created() {
    const paramAlarmRuleSn = this.$route.params.alarmRuleSn;
    this.doGetRuleDetail(paramAlarmRuleSn);
  },
};
</script>

<style scoped>
@import url('./rule.css');
</style>
